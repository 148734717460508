<template>
  <!-- fullhd:w-full -->
  <h1 class="laptop:w-4/5 w-full laptop:text-7xl fullhd:text-10xl text-35px lg:text-7xl md:text-52xl md:w-full">
    <slot />
  </h1>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'MARSHeading'
})
</script>

<style scoped>

</style>
